import React from "react";

const style = {
    "max-width": "250px"
};

function OldTraditions2(){

    return (
        <div className="TaskWrapper">
            <div className="Task">

                <h1>Old Tradition</h1>

                <img src="https://c.tenor.com/qAy1e3z1P9UAAAAC/fun-excited.gif" style={style}/>
            
                <p className="description">
                    Zu fast schon einer Tradition wurde beim Pivo Samstig das “Pivo to Go”. Nun wäre es natürlich doch nur all zu Schade, wenn ich euch dem trockenen Winter-Wetter komplett ohne Speiseröhrenbefeuchtungsmittel aussetzen würde.
                    <br /><br />
                    <i>Obacht Hoi!</i> Das Pub ist doch nicht einfach ein Beer Shop. Ganz in eurer nähe befindet sich nämlich ein ganz interessanter Shop, wo ihr viele verschiedene Biere findet.
                    
                    <h2>
                        Geht zum nächsten Beer Shop und besorgt dort min. 3 verschiedene Biere.
                    </h2>
                    <i><a href="https://goo.gl/maps/YTo1rfmKtNXToCc8A" target="_blank">Wo</a> könnte es den ganz viele verschiedene <a href="https://goo.gl/maps/YTo1rfmKtNXToCc8A" target="_blank">Biere</a> in eurer <a href="https://goo.gl/maps/YTo1rfmKtNXToCc8A" target="_blank">Nähe</a> geben, ich frage mich <a href="https://goo.gl/maps/YTo1rfmKtNXToCc8A" target="_blank">Wo genau?</a></i>
                    <br /><br />
                    <h3>
                        Macht ein Bild von allen gekauften Bieren und dem Shop. <br /> Postet es im Chat, dann erhaltet ihr den nächsten Hinweis
                    </h3>
                    <br /><br />

                    <b>WICHTIG</b> <br />
                    Wann ihr das erworbene Bier trinkt, ist euch überlassen.
                    <br />
                    <i>Ich empfehle aber, noch kurz damit zu warten</i><br />
                    <b>Das Glas oder die Dose mit der Etikette müsst ihr beim Treffpunkt dem Spielleiter vorweisen.</b>
                    <br /><br />
                    <br /><br />

                    Wer sich jetz immer noch fragt, wer Shaun und Ed sind und was sie mit dem Winchester zu tun haben, <a href="https://www.youtube.com/watch?v=MeJzHSxRq40&ab_channel=ChrisConner" target="_blank">hier</a> eine kurze Aufklärung.
                </p>
            </div>

        </div>
    );
}

export {OldTraditions2}