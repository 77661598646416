import React from "react";
import { Link } from "react-router-dom";

function BackToBase(){

    return (
        <div className="TaskWrapper">
            <div className="Task">

                <h1>Back to Base</h1>
            
                <p className="description">
                <i>“Wer hat an der Uhr gedreht, ist es wirklich schon so spät? Soll das heissen ja ihr Leut mit dem Paul ist schluss für heut?”</i>
                <br />
                <img src="https://c.tenor.com/MRbeyIZEJicAAAAC/pink-panther-need-more-sleep.gif"/>
                <br /><br />

                Wie die Zeit vergeht!<br /><br />
                Endo Anaconda (RIP) verrät euch <i>töif  täif drin, was mir alli sy</i> und somit auch unser gemeinsames Ziel in seinem nur allzu bekannten Hit.
                <br /><br />
                Macht euch bei Zeiten auf den Weg zum Treffpunkt, die Reise zum Ziel treten wir alle gemeinsam an.                
                <br /><br />
                Da ihr jetzt wissen solltet, wo es anschliessend hin geht, ist es auch ganz ganz ganz wichtig (ja Wäber, würkli ganz wichtig), dass wir uns pünktlich treffen. Schaut das ihr um <b>16.15 Uhr</b> am Treffpunkt seit. Von da aus geht es dann gemeinsam weiter.
                <br /><br />
                Treffpunkt <b>16.15 Uhr</b> (spätestens) <a href="https://goo.gl/maps/4faoN95txpuwp8ed8" target="_blank">Hier</a><br />
                <i>Also im Untergeschoss, nöd uf de Strass, det womer üs sust ammel träffed, ihr wüssed scho wo. Nei nöd bim Fette Engel, unedra, gad näb de Rollträppe, die hend au immer ganz viel <i>Piöör</i> dete. Ja genau, dete.</i>

                <br /><br /><br /><br />
                <i>Wer sein Bier noch aufgespart hat - ab jetzt wäre kein schlechter Zeitpunkt, da es noch einen Moment dauert, bis wir dann am Ziel sind. Cheers</i>
                </p>
            </div>
        </div>
    );
}

export {BackToBase}