import './Footer.css';

function Footer() {
    return (
        <footer className="Footer">
            (c) Pivo Samstig 2022
        </footer>
    );
}

export default Footer;
